var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"videos"},[_c('div',{staticClass:"video-box",on:{"click":_vm.videoClick}},[_c('div',{staticClass:"dplayer"},[_c('div',{ref:"dplayer",attrs:{"playsinline":"","webkit-playsinline":""}})])]),(
      _vm.verifyData.mediaInfo &&
      !_vm.verifyData.playable &&
      !_vm.verifyData.mediaInfo.isVip
    )?_c('div',{staticClass:"prevtime-box"},[(_vm.handleUrl() == _vm.path)?_c('div',{staticClass:"prevtime"},[_c('p',[_vm._v("视频可试看 "+_vm._s(this.verifyData.mediaInfo.prevtime)+" 秒")]),_vm._m(0)]):_vm._e(),(
        _vm.verifyData.mediaInfo &&
        _vm.verifyData.mediaInfo.isVip &&
        _vm.userInfo.vipType == 0 && _vm.handleUrl() == _vm.path
      )?_c('div',{staticClass:"prevtime"},[_c('p',[_vm._v("非VIP可试看 "+_vm._s(this.verifyData.mediaInfo.prevtime)+" 秒")]),_vm._m(1)]):_vm._e()]):_vm._e(),(
      _vm.verifyData.mediaInfo &&
      _vm.verifyData.mediaInfo.isVip &&
      _vm.userInfo.vipType == 0
    )?_c('div',{staticClass:"prevtime-box"},[(this.verifyData.ycLeftFreeNum > 0 && _vm.handleUrl() == _vm.path || (this.verifyData.ycLeftFreeNum <= 0 && this.verifyData.playable && _vm.handleUrl() == _vm.path))?_c('div',{staticClass:"prevtime"},[_c('p',[_vm._v("免费次数剩余: "+_vm._s(this.verifyData.ycLeftFreeNum))])]):(_vm.handleUrl() == _vm.path)?_c('div',{staticClass:"prevtime"},[_c('p',[_vm._v("非VIP可试看 "+_vm._s(this.verifyData.mediaInfo.prevtime)+" 秒")]),_vm._m(2)]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isShowbuy),expression:"isShowbuy"}],staticClass:"bugPopUps"},[_c('div',{staticClass:"buyPopUps-box"},[_c('div',{staticClass:"buy-info"},[_c('p',[_vm._v(_vm._s(_vm.videoDialog.topBuyInfoText))]),_c('p',[_vm._v(_vm._s(_vm.videoDialog.bottomBuyInfoText))])]),_c('div',{staticClass:"buy-btn-box"},[_c('span',{on:{"click":_vm.leftBuying}},[_vm._v(_vm._s(_vm.videoDialog.leftBtnText))]),_c('span',{on:{"click":_vm.rightBuying}},[_vm._v(_vm._s(_vm.videoDialog.rightBtnText))])])])]),_c('videomember',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoMemberShow),expression:"videoMemberShow"}],attrs:{"topicInfo":_vm.verifyData.mediaInfo,"videoMemberShow":_vm.videoMemberShow},on:{"videoMemberClose":function($event){_vm.videoMemberShow = false}}}),_c('FanDialog',{attrs:{"isfanShow":_vm.isfanShow,"fansGroup":_vm.verifyData.fansGroup},on:{"fanClose":function($event){_vm.isfanShow = false},"fanSuc":_vm.fanSuc}}),_c('videopay',{directives:[{name:"show",rawName:"v-show",value:(_vm.videoPayShow),expression:"videoPayShow"}],attrs:{"topicInfo":_vm.verifyData.mediaInfo,"videoPayShow":_vm.videoPayShow},on:{"joinFans":function($event){(_vm.isfanShow = true), (_vm.videoPayShow = false)},"videoPayClose":function($event){_vm.videoPayShow = false}}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("购买")]),_vm._v("即可永久观看")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("充值VIP")]),_vm._v("无限看")])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',[_vm._v("充值VIP")]),_vm._v("无限看")])}]

export { render, staticRenderFns }