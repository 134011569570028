<template>
  <div class="forward">
    <van-popup v-model="isForward" position="bottom" round @click-overlay="$emit('forwardClose')" :close-on-click-overlay="false" @close="close">
      <div class="content">
        <h2>转发视频</h2>
        <textarea class="textarea" :rows="6" placeholder="请输入文字描述" id="textarea">
        </textarea>
        <div class="forward-box">
          <div class="searea" @click="sendVideo">
            <div class="searea-icon">
              <svg-icon iconClass="forward" class="forward-icon"></svg-icon>
            </div>
            <div class="searea-text">转发至色区</div>
          </div>
          <div class="searea" @click="copyLink">
            <div class="searea-icon">
              <svg-icon iconClass="link" class="forward-icon"></svg-icon>
            </div>
            <div class="searea-text">复制链接</div>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { publishSeBaVideo } from "@/api/index.js";
export default {
  props: {
    isForward: {
      require: true,
      default: false,
    },
    id: {
      require: true,
      default: 0,
    },
  },
  methods: {
    async sendVideo() {
      let content = document.querySelector("#textarea").value;
      if (!content) {
        this.$toast("转发描述不能为空！");
        return;
      }
      let req = {
        content: content,
        repostMediaId: this.id,
      };
      let ret = await this.$Api(publishSeBaVideo, req);
      if (ret.code == 200) {
        this.$emit("forwardClose");
        this.$toast("转发成功");
        return;
      }
      this.$toast(ret.tip || "转发失败");
    },
    copyLink() {
      //点击复制链接
      this.$copyText(this.$store.getters.userInfo.inviteUrl).then(
        () => {
          this.$toast("复制链接成功，快去分享吧！");
        },
        () => {
          this.$toast("复制链接失败");
        }
      );
    },
    close() {
      let textareaDom = document.querySelector("#textarea");
      textareaDom.value = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.forward {
  .content {
    padding: 30px 10px;
    h2 {
      font-size: 14px;
      font-weight: 500;
    }
    .textarea {
      margin: 10px 0;
      width: 100%;
      color: #515151;
      box-sizing: border-box;
      background: #d6d5d5;
      border: none;
      border-radius: 6px;
      padding: 10px 6px;
    }
    .forward-box {
      display: flex;
      justify-content: space-between;
      .searea {
        display: flex;
        justify-items: center;
        align-items: center;
        flex-direction: column;
        margin: 15px 50px;
        .searea-text {
          padding: 10px 0;
        }
        .searea-icon {
          width: 48px;
          height: 48px;
          background: $vermillion;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          .forward-icon {
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
}
</style>