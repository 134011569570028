<template>
  <div class="selectionLinePopup">
    <!-- 选择路线弹窗 -->
    <van-popup
      class="selectionPopup"
      v-model="isOpen"
      position="bottom"
      @close="close"
    >
      <div class="selectionLineBox">
        <div class="titleBox">
          选择播放线路
          <div class="close" @click.stop="close">
            <svg-icon icon-class="closeBlack" />
          </div>
        </div>
        <div class="lineBox">
          <div
            class="line"
            :class="{ selectedLine: item.desc == videoRoadLine.desc }"
            @click.stop="selectLine(item)"
            v-for="item in roadLines"
            :key="item.desc"
          >
            {{ item.desc }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { Toast } from "vant";
import { mapGetters } from "vuex";

export default {
  name: "selectionLinePopup",
  data() {
    return {
      roadLines: [],
      isOpen: true,
    };
  },
  computed: {
    ...mapGetters({
      videoRoadLine: "videoRoadLine", // 选择的线路
    }),
  },
  created() {
    // console.log(this.videoRoadLine)
    let appConfig = this.$store.getters.appConfig;
    console.log(appConfig)
    let imgDomainList = appConfig?.videoCfg?.newCdns;
    if (imgDomainList && imgDomainList.length > 0) {
      this.roadLines = imgDomainList;
    }
  },
  methods: {
    selectLine(item) {
      this.$store.commit("video/SET_VIDEOROADLINE", item);
      Toast("切换成功");
      this.$emit("closeSelectLine");
    },
    close() {
      // console.log("触发");
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .van-overlay {
  background-color: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(5px);
}
/deep/ .van-popup {
  background-color: transparent;
}
.selectionLinePopup {
  overflow: hidden;
  .selectionPopup {
    .selectionLineBox {
      background-image: linear-gradient(
        to bottom,
        rgb(255, 235, 217),
        rgb(255, 251, 247),
        rgb(255, 255, 255)
      );
      border-radius: 18px 18px 0 0;
      .titleBox {
        color: rgb(21, 21, 21);
        font-size: 18px;
        font-weight: 500;
        height: 57px;
        text-align: center;
        line-height: 57px;
        border-bottom: 1px solid rgb(229, 222, 211);
        position: relative;
        .close {
          position: absolute;
          right: 20px;
          top: 0px;
          > svg {
            height: 20px;
            width: 20px;
          }
        }
      }
      .lineBox {
        padding: 15px 16px 50px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 18px;
        grid-row-gap: 14px;
        overflow-y: auto;
        height: 250px;
        .line {
          height: 40px;
          width: 100px;
          text-align: center;
          line-height: 40px;
          border-radius: 5px;
          font-size: 14px;
          color: white;
          background: rgba(0, 0, 0, 0.4);
        }
        .selectedLine {
          color: white;
          background: rgb(230, 136, 56);
        }
      }
    }
  }
}
</style>
